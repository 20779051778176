import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import getValidUrl from '../../Utilities/getValidUrl';
import axios from 'axios';
import { eventId } from '../../config';
import { useSelector } from 'react-redux';
import { useGetEventQuery } from '../../Services/queryApi';

// Translations
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export default function Floorplan(props) {
  const classes = useStyles();
  const [floorplan, setFloorplan] = useState([]);
  // access redux to get strapi
  const strapiPagesRedux = useSelector((state) => state.strapi);
  const eventInformation = useSelector((state) => state.event);
  // configure menu item

  const { data, error, isLoading } = useGetEventQuery(eventId);
  // let venueAccountId = props?.content?.replace('<p>', '').replace('</p>', '');
  // console.log('venueAccountId:', venueAccountId);

  // translation
  const { t, i18n } = useTranslation();

  function createFloorplan() {
    let iframe = (
      <>
        <Typography variant="h4" style={{ marginBottom: '16px' }}>
          {t('landing.floorplan')}
        </Typography>
        <div dangerouslySetInnerHTML={{ __html: props.content }} />
      </>
    );
    setFloorplan(iframe);
  }

  useEffect(() => {
    createFloorplan();
  }, [i18n.language]);

  return floorplan;
  // return floorplan;
}
