import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Tabs, Tab, Typography, Chip, Divider } from '@material-ui/core';

import { useSelector } from 'react-redux';

// Translations
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export default function EventHorizonAbout(props) {
  const classes = useStyles();
  const event = useSelector((state) => state.event.event);

  // translation
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h4">EventHorizon</Typography>
      <br />
      <Typography variant="body1">{t('landing.eventHorizonAbout')}</Typography>
    </>
  );
}
