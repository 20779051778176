export const attendeeGridOptions = [
  {
    id: 'last_name',
    numeric: false,
    disablePadding: false,
    label: 'Last Name',
  },
  {
    id: 'first_name',
    numeric: false,
    disablePadding: false,
    label: 'First Name',
  },
  { id: 'site', numeric: false, disablePadding: false, label: 'Site' },
  //   { id: 'title', numeric: false, disablePadding: false, label: 'Title' },
  {
    id: 'department',
    numeric: false,
    disablePadding: false,
    label: 'Department',
  },
  // {
  //   id: 'division',
  //   numeric: false,
  //   disablePadding: false,
  //   // label: 'GBU', // Sanofi specific
  //   label: 'Division',
  // },
  // {
  //   id: 'days',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Attendance',
  // },
  // { id: 'modality', numeric: false, disablePadding: false, label: 'Modality' },
];

export const exhibitorGridOptions = [
  {
    id: 'last_name',
    numeric: false,
    disablePadding: false,
    label: 'Last Name',
  },
  {
    id: 'first_name',
    numeric: false,
    disablePadding: false,
    label: 'First Name',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'account_name',
    numeric: false,
    disablePadding: false,
    label: 'Company',
  },
  {
    id: 'roles',
    numeric: false,
    disablePadding: false,
    label: 'Role(s)',
  },
  // {
  //   id: 'days',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Attendance',
  // },
  // { id: 'site', numeric: false, disablePadding: false, label: 'Site' },
  //   { id: 'title', numeric: false, disablePadding: false, label: 'Title' },
  // {
  //   id: 'department',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Organization',
  // },
  // { id: 'modality', numeric: false, disablePadding: false, label: 'Modality' },
];
